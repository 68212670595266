<template>
 <div class="my-box">

  <el-dialog title="查看明细" top="5%" :visible.sync="showSetDoorbell" width="50%">
   <el-form label-width="150px" ref="setForm" :model="setForm">
    <el-row :gutter="10" style="margin-bottom: 0">
     <el-col :span="8">
      <el-form-item label="员工姓名：">
       {{setForm.employeeName}}
      </el-form-item>
     </el-col>
     <el-col :span="8">
      <el-form-item label="时间：">
       {{setForm.opsDate}}
      </el-form-item>
     </el-col>
     <el-col :span="8">

      <el-form-item label="" label-width="10px">
       <el-button type="primary" v-if="showImprot" @click="exportExcel(0)">导出</el-button>
      </el-form-item>
     </el-col>
    </el-row>
    <el-row :gutter="10" style="margin-bottom: 0">
     <el-col :span="8">
      <el-form-item label="到房次数：">
       {{tablist.length}}
      </el-form-item>
     </el-col>
     <el-col :span="8">
      <el-form-item label="到房时长过长预警：">
       {{count}}次
      </el-form-item>
     </el-col>
    </el-row>

   </el-form>
   <el-card>
   <el-table :data="tablist" v-loading="loading" id="tab" height="400" style="width: 100%">
    <el-table-column v-for="(item,i) in execlTab" :key="i" :width="item.width" :prop="item.value" :label="item.label">
     <template slot-scope="scope">
      <div v-if="item.value=='duration'">
       {{showLongTimes(scope.row.duration)}}
      </div>
      <div v-else>
       {{scope.row[item.value]}}
      </div>
     </template>
    </el-table-column>
   </el-table>
   
  </el-card>

   <div slot="footer" class="dialog-footer ">
    <el-button size="small" @click="showSetDoorbell = false">关闭</el-button>
   </div>
  </el-dialog>
 </div>
</template>
<script>
import { export_json_to_excel } from "@/vendor/Export2Excel.js";
import { trackDetail } from "@/api";
import {
 getLongDateTime,
 secondGetLongTime,
 showLabel,
} from "@/api/common.js";
export default {
 data() {
  let changeTime = (rule, value, callback) => {
   if (!this.doorbellTime) {
    callback(new Error("请选择时间范围"));
   } else {
    callback();
   }
  };
  return {
    loading: false,
   downLoading: false,
    tablist: [],
   execlTab: [
    {
     label: "插卡房号",
     value: "roomNumber",
    },
   
    {
     label: "开始时间",
     value: "startDate",
    },
    {
     label: "结束时间",
     value: "endDate",
    },

    {
     label: "持续时长",
     value: "duration",
    
    },
   
   ],
   warningLongTime:3600,//单位：秒
   showSetDoorbell: false,
   rules: {
    doorbellTime: [{ validator: changeTime, trigger: ["blur", "change"] }],
   },
   doorbellTime: null,
   setForm: {},
  };
 },
computed: {
 count(){
    let val=0
    this.tablist.forEach(v=>{
      if(v.duration>this.warningLongTime){
        val++
      }
    })
    // this.tablist.reduce((total, currentValue, currentIndex,)=>{
    //  console.log('total--',total, currentValue, currentIndex);
    //  total++
    //  return total
    // }, 0)
    return val
  },
  showImprot(){
    return   this.$store.state.roleMenu.includes('workertrack:import')
  },
  
  },
 created() {},
 methods: {
   // 导出
  exportExcel(i) {
   this.downLoading = true;
   if (this.loading) {
    if (i < 5) {
     setTimeout(() => {
      this.exportExcel(i);
     }, 1000);
    }
   } else {
    if (!this.tablist || this.tablist.length == 0) {
     this.$message.warning("未加载到数据");
     this.downLoading = false;
     return;
    }
    let tHeader = [];
    let filterVal = [];
    this.execlTab.forEach((v) => {
     tHeader.push(v.label);
     filterVal.push(v.value);
    });
    let list = this.getExeclData();
    let data = this.formatJson(filterVal, list);
    setTimeout(() => {
     export_json_to_excel(tHeader, data, `${this.setForm.employeeName}${this.setForm.opsDate}到房记录`);
     this.downLoading = false;
    }, 500);
   }
  },
  // d导出execl数据转换
  getExeclData() {
   let arr = new Array();
   this.tablist.forEach((v) => {
    let obj = Object.assign({}, v);
    obj.duration = this.showLongTimes(v.duration);
    arr.push(obj);
   });
   return arr;
  },
  formatJson(filterVal, jsonData) {
   return jsonData.map((v) => filterVal.map((j) => v[j]));
  },
    // 时长
  showLongTimes(val) {
   return secondGetLongTime(val);
  },


  openSet(data) {
   this.showSetDoorbell = true;
   if (data) {
    let reqData={}
    reqData.brandId=data.brandId
    reqData.hotelId=data.hotelId
    reqData.employeeName=data.employeeName
    reqData.opsDate=data.opsDate
    // "2021-03-03"
    // data.opsDate
    reqData.cardSn=data.cardSn
     this.setForm = Object.assign({}, reqData);
     this.tablist=[]
     console.log(this.setForm,'this.setForm');
    this.getList()
   } else {
    this.setForm = {};
   }
  },
  getList(){
     this.loading = true;
   trackDetail(this.setForm)
    .then((res) => {
     this.loading = false;
     if (res.data.code == "000000") {
      this.tablist = res.data.data;
     } else {
      this.$message.warning(res.data.message);
     }
    })
    .catch((err) => {
     this.loading = false;
    });
   
  }
 

 },
};
</script>
<style lang="scss" scoped>

.my-box {
 

 .el-row {
  margin-bottom: 10px;
  &:last-child {
   margin-bottom: 0;
  }
 }
 .el-col {
  border-radius: 4px;
 }
}
</style>


