var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看明细",
            top: "5%",
            visible: _vm.showSetDoorbell,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSetDoorbell = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "setForm",
              attrs: { "label-width": "150px", model: _vm.setForm },
            },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "margin-bottom": "0" },
                  attrs: { gutter: 10 },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "员工姓名：" } }, [
                        _vm._v(
                          "\n      " +
                            _vm._s(_vm.setForm.employeeName) +
                            "\n     "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "时间：" } }, [
                        _vm._v(
                          "\n      " + _vm._s(_vm.setForm.opsDate) + "\n     "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "", "label-width": "10px" } },
                        [
                          _vm.showImprot
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.exportExcel(0)
                                    },
                                  },
                                },
                                [_vm._v("导出")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-bottom": "0" },
                  attrs: { gutter: 10 },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "到房次数：" } }, [
                        _vm._v(
                          "\n      " + _vm._s(_vm.tablist.length) + "\n     "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "到房时长过长预警：" } },
                        [_vm._v("\n      " + _vm._s(_vm.count) + "次\n     ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-card",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tablist, id: "tab", height: "400" },
                },
                _vm._l(_vm.execlTab, function (item, i) {
                  return _c("el-table-column", {
                    key: i,
                    attrs: {
                      width: item.width,
                      prop: item.value,
                      label: item.label,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              item.value == "duration"
                                ? _c("div", [
                                    _vm._v(
                                      "\n      " +
                                        _vm._s(
                                          _vm.showLongTimes(scope.row.duration)
                                        ) +
                                        "\n     "
                                    ),
                                  ])
                                : _c("div", [
                                    _vm._v(
                                      "\n      " +
                                        _vm._s(scope.row[item.value]) +
                                        "\n     "
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer ",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showSetDoorbell = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }